import { StreamKind } from '../../../../models/playlist-stream.model'

export interface StreamTypeInfo {
  kind: StreamKind
  name: string
  color: string
  icon: string
  comingSoon?: boolean
  hint?: string
  disabled?: boolean
  dowowOnly?: boolean
  isForPaidOnly?: boolean
  isForProPaidOnly?: boolean
}

export function getStreamTypesList(): StreamTypeInfo[] {
  return [
    <StreamTypeInfo>{
      kind: 'twitter',
      name: 'Twitter',
      color: '#00ACEE',
      icon: 'dw-Twitter-X',
      hint: 'Twitter',
      disabled: true,
    },
    <StreamTypeInfo>{
      kind: 'twitterV2',
      name: 'Twitter v2',
      color: '#00ACEE',
      icon: 'dw-Twitter-X',
      hint: 'Twitter',
      isForPaidOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'gdrive',
      name: 'GDrive',
      color: '#4885ED',
      icon: 'dw-google-drive',
      hint: 'GDrive',
    },
    <StreamTypeInfo>{
      kind: 'instagram',
      name: 'Instagram',
      color: '#DD2A7B',
      icon: 'dw-instagram',
      hint: 'Instagram',
    },
    <StreamTypeInfo>{
      kind: 'iframe',
      name: 'Iframe',
      color: '#8976C6',
      icon: 'dw-iframe',
      hint: 'Iframe',
    },
    <StreamTypeInfo>{
      kind: 'zoom',
      name: 'Zoom',
      color: '#2D8CFF',
      icon: 'dw-zoom',
      hint: 'Zoom',
    },
    <StreamTypeInfo>{
      kind: 'youtube',
      name: 'Youtube',
      color: '#FF0000',
      icon: 'dw-youtube',
      hint: 'YouTube',
    },
    <StreamTypeInfo>{
      kind: 'youtubeChat',
      name: 'YT Chat',
      color: '#FF0000',
      icon: 'dw-youtube',
      hint: 'YT Chat',
    },
    <StreamTypeInfo>{
      kind: 'wonderMe',
      name: 'Wonder',
      color: '#0F7A78',
      icon: 'dw-wonderme',
      hint: 'Wonder',
      disabled: true,
    },
    <StreamTypeInfo>{
      kind: 'nft',
      name: 'NFT',
      color: '#2081E2',
      icon: 'dw-opensea',
      hint: 'OpenSea (NFT)',
    },
    <StreamTypeInfo>{
      kind: 'ethNft',
      name: 'ETH-NFT',
      color: '#00A4D6',
      icon: 'dw-ethereum',
      hint: 'ETH NFT',
      isForProPaidOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'solanaNft',
      name: 'Solana',
      color: '#D84F25',
      icon: 'dw-solana',
      hint: 'Solana NFT',
      isForProPaidOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'dclNft',
      name: 'DCL',
      color: '#ec5e5c',
      icon: 'dw-dcl',
      hint: 'DCL NFT',
    },
    <StreamTypeInfo>{
      kind: 'precisionx',
      name: 'Precision X',
      color: '#100021',
      icon: 'dw-stroer-px',
      hint: 'Precision X',
      dowowOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'liveStream',
      name: 'LIVE',
      color: '#936EFB',
      icon: 'dw-dowow-solid',
      hint: 'doWow-live',
      dowowOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'liveTv',
      name: 'm3u8',
      color: '#f22ee5',
      icon: 'dw-film',
      hint: 'm3u8',
    },
    <StreamTypeInfo>{
      kind: 'inworldai',
      name: 'Inworld AI',
      color: '#561FE5',
      icon: 'dw-inworld-ai',
      hint: 'Inworld',
      dowowOnly: true,
      isForPaidOnly: true,
      isForProPaidOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'inworldai3d',
      name: 'Inworld AI 3D',
      color: '#561FE5',
      icon: 'dw-inworld-ai',
      hint: 'Inworld 3D',
      dowowOnly: true,
      isForPaidOnly: true,
      isForProPaidOnly: true,
    },
    <StreamTypeInfo>{
      kind: 'onedrive',
      name: 'One Drive',
      color: '#4F8AD8',
      icon: 'dw-onedrive',
      hint: 'One Drive',
    },
    <StreamTypeInfo>{
      kind: 'chatBot',
      name: 'AI Chatbot',
      color: '#6244B9',
      icon: 'dw-AIChatBot',
      hint: 'AI Chatbot',
    },
    <StreamTypeInfo>{
      kind: 'kraken',
      name: 'Kraken',
      color: '#00A4D6',
      icon: 'dw-kraken',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'polygon',
      name: 'Polygon',
      color: '#7950DD',
      icon: 'dw-polygon',
      comingSoon: true,
    },
    // <StreamTypeInfo>{
    //   kind: 'adshares',
    //   name: 'AdShares',
    //   color: '#2081E2',
    //   icon: 'dw-twitter',
    //   hint: 'AdShares',
    // },
    <StreamTypeInfo>{
      kind: 'miro',
      name: 'Miro',
      color: '#E6465A',
      icon: 'dw-miro',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'airtable',
      name: 'Airtable',
      color: '#F4AC0B',
      icon: 'dw-airtable',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'slido',
      name: 'Slido',
      color: '#5AAB34',
      icon: 'dw-slido',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'airmeet',
      name: 'Airmeet',
      color: '#3EAEF5',
      icon: 'dw-airmeet',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'spatialChat',
      name: 'SpatialChat',
      color: '#278EF3',
      icon: 'dw-spatialchat',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'crowdcast',
      name: 'Crowdcast',
      color: '#EA9D21',
      icon: 'dw-crowdcast',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'twitch',
      name: 'Twitch',
      color: '#4E298F',
      icon: 'dw-twitch',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'vimeo',
      name: 'Vimeo',
      color: '#1D9FD9',
      icon: 'dw-vimeo',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'typeform',
      name: 'Typeform',
      color: '#79BDB2',
      icon: 'dw-typeform',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'calendly',
      name: 'Calendly',
      color: '#074CF4',
      icon: 'dw-calendly',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'spotify',
      name: 'Spotify',
      color: '#25D34E',
      icon: 'dw-spotify',
      comingSoon: true,
    },
    <StreamTypeInfo>{
      kind: 'restream',
      name: 'Restream',
      color: '#182A4E',
      icon: 'dw-Restream',
      hint: 'Restream',
      disabled: true,
    },
    <StreamTypeInfo>{
      kind: 'gogleanalytics',
      name: 'GoogleAnalytics',
      color: '#FF9B0B',
      icon: 'dw-google-analytics',
      hint: 'Google Analytics',
      disabled: true,
    },
  ]
}

function getStreamTypesInformationMap(): { [id: string]: StreamTypeInfo } {
  let list = getStreamTypesList()
  let result = {}
  list.forEach(item => {
    result[item.kind] = item
  })
  return result
}

export const StreamTypesInformation: {
  [id: string]: StreamTypeInfo
} = getStreamTypesInformationMap()
